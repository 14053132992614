import Navbar from "../../Component/Navbar";
import TextField from "../../Component/TextField";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { AnimatePresence } from "framer-motion";
import Alert from "../../Component/Alert";
import { Eye24Regular, EyeOff24Regular } from "@fluentui/react-icons";


export default function LandingPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [beritaList, setBeritaList] = useState([]);
  const [nim, setNim] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [alert, setAlert] = useState({
    show: false,
    message: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
        });
      }, 2500);
    }
    fetchData();
    return () => clearTimeout(timer);
  
  }, [alert.show]);
  const convertDateFormat = (date) => {
    const day = date.substring(0, 2);
    const month = date.substring(2, 4);
    const year = date.substring(4, 8);
    return `${year}${month}${day}`;
  };
  const showEye = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const formattedDate = convertDateFormat(tanggalLahir);

      const response = await axios.post("https://alumni-be.fti.ukdw.ac.id/api/a", {
        nim,
        tanggal_lahir: formattedDate,
      });

      if (!response.data || !response.data.token) {
        console.log(response.data);
        throw new Error("Unexpected response format");
      }
      setNim(nim);
      Cookies.set("nim", nim);
      const token = response.data.token;
      const role = response.data.role;
      Cookies.set("token", token);
      Cookies.set("role", role);

      if (response.data.redirect == "reset_password") {
        navigate(`/alumni/reset`);
      } else {
        navigate(`/alumni/${nim}`);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.error("Login failed:", error.response.data.message);
      } else {
        console.error("Login failed:", error.message);
      }
      setAlert({
        show: true,
        message: "Username atau password salah",
      });
      return;
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("https://alumni-be.fti.ukdw.ac.id/api/berita");
      setBeritaList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-[#E9EBEF] h-auto pb-4">
      <Navbar />
      {/* Login */}
      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6 mt-8`}
              variant="error"
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      <section className="">
        <div className="">
          <div className="flex flex-wrap items-center justify-center ml-[6rem]">
            <div className="w-[300px]">
              <form onSubmit={handleLogin}>
                <div className="relative">
                  {/* <TextField
                    label="NIM"
                    autoComplete="on"
                    placeholder="71210999"
                    value={nim}
                    onChange={(event) => setNim(event.target.value)}
                    className="w-[500px]"
                  ></TextField> */}
                  <TextField
                    id="email-input"
                    label="NIM"
                    className={`px-4 py-2 outline-none rounded-md w-full border ${
                      alert.show ? "border-red-500" : "border-neutral-30"
                    } `}
                    placeholder= "71200552"
                    type="text"
                    value={nim}
                    onChange={(event) => setNim(event.target.value)}
                  />
                </div>
                <div className="relative mb-6" data-twe-input-wrapper-init="">
                  <TextField
                    label="Password"
                    autoComplete="on"
                    placeholder="ddmmyyy/password"
                    value={tanggalLahir}
                    type={showPassword ? "text" : "password"}
                    onChange={(event) => setTanggalLahir(event.target.value)}
                    className={`px-4 py-2 outline-none rounded-md w-full border ${
                      alert.show ? "border-red-500" : "border-neutral-30"
                    } `}
                  ></TextField>
                  <button
                    id="eye-button"
                    className="absolute right-3 top-[3rem] transform -translate-y-1/2"
                    type="button"
                    onClick={showEye}
                  >
                    {showPassword ? <Eye24Regular /> : <EyeOff24Regular />}
                  </button>
                </div>
                <div className="text-center lg:text-left">
                  <button
                    type="button"
                    className="inline-block w-full rounded bg-primary px-7 pb-2 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                    data-twe-ripple-init=""
                    data-twe-ripple-color="light"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
            <div className="ml-[200px]">
              <img
                src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                className="w-[600px]"
                alt="Sample image"
              />
            </div>
          </div>
        </div>
      </section>

      {/* divide */}
      <div class="flex justify-center items-center">
        <div class="text-center bg-slate-600 w-[80rem] h-[50px] pt-[10px] pb-[60px] rounded">
          <h3 className="text-white">Tracer Study</h3>
          <p className="text-white">Universitas Kristen Duta Wacana</p>
        </div>
      </div>

      <div class="mt-6 flex items-center justify-center mb-6">
        <div class="w-[400px] h-[390px] block rounded-lg bg-white p-6 text-surface shadow-secondary-1 dark:bg-surface-dark dark:text-white mr-[40px] ml-[10px]">
          <h5 class="mb-2 text-xl font-medium leading-tight text-center">
            <b>INFORMASI</b>
          </h5>
          <div class="">
            <p>
              Tracer Study bertujuan untuk memperoleh UMPAN BALIK yang bertujuan
              untuk:
            </p>
            <ul className="list-disc ml-[40px]">
              <li>
                Mengetahui keberadaan/aktivitas alumni setelah lulus kuliah.
              </li>
              <li>
                Mengukur kompetensi lulusan dan permintaan pasar kerja (link and
                match).
              </li>
              <li>Memetakan pasar kerja.</li>
            </ul>
            <p>
              Dari hasil umpan balik tersebut pada akhirnya akan membantu
              Universitas Kristen Duta Wacana untuk:
            </p>
            <ul className="list-disc ml-[40px]">
              <li>Menyiapkan lulusan yang sesuai dengan dunia kerja.</li>
              <li>Mengukur capaian pembelajaran.</li>
            </ul>
          </div>
        </div>
        <div class="w-[400px] h-[390px] block rounded-lg bg-white p-6 text-surface shadow-secondary-1 dark:bg-surface-dark dark:text-white mr-[40px] ">
          <h5 class="mb-2 text-xl font-medium leading-tight text-center">
            <b>FILE TRACER STUDY</b>
          </h5>
          <div class="">
            <p>File Tracer Study bisa di unduh di:</p>
            <ul className="list-disc ml-[40px]">
              <li>
                Surat Resmi Pengisian Kuesioner Wakil Rektor Bidang III{" "}
                <a href="http://tracerstudy.ukdw.ac.id/alumni_files/surat_edaran_tracer_study.pdf">
                  <b>[Download]</b>
                </a>
              </li>
              <li>
                Surat Evaluasi Kepuasan Pengguna
                <a href="https://tracerstudy.ukdw.ac.id/alumni_files/form_tracer_study_2018.pdf">
                  <b> [Download]</b>
                </a>
              </li>
              <li>
                Form Evaluasi Pengguna Lulusan UKDW
                <a href="https://tracerstudy.ukdw.ac.id/alumni_files/form_tracer_study_2018.pdf">
                  <b> [unduh-doc]</b> <b> [unduh-pdf]</b>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="w-[400px] h-[390px] block rounded-lg bg-white p-6 text-surface shadow-secondary-1 dark:bg-surface-dark dark:text-white mr-[10px]">
          <h5 class="mb-2 text-xl font-medium leading-tight text-center">
            <b>USERNAME DAN PASSWORD</b>
          </h5>
          <div class="">
            <p>Login Mahasiswa:</p>
            <ul className="list-disc ml-[40px]">
              <li>
                User : Mengunakan NIM (Nomor Induk Mahasiswa) yang pernah
                dimiliki sewaktu kuliah.{" "}
              </li>
              <li>
                Password : Menggunakan tanggal lahir alumni yang bersangkutan
                dengan format ddmmyyyy (2 digit tanggal, 2 digit bulan, 4 digit
                tahun kelahiran) contoh : 01011990(1 januari 1990)
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="flex justify-center items-center">
        <div class="text-center bg-slate-600 w-[80rem] h-[50px] pt-[10px] pb-[60px] rounded">
          <h3 className="text-white">Berita Acara</h3>
          <p className="text-white">Universitas Kristen Duta Wacana</p>
        </div>
      </div>

      {/* Card Berita */}
      <div class="mt-6 flex items-center justify-center mb-6">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {beritaList.map((berita) => (
            <div
              key={berita.id}
              className="w-[400px] h-auto block rounded-lg bg-white p-6 text-surface shadow-secondary-1 dark:bg-surface-dark dark:text-white ml-[15px]"
            >
              <a href="#!">
                <img
                  className="rounded-t-lg h-[300px] w-[390px]"
                  src={berita.gambar}
                  alt="asasas"
                />
              </a>
              <div className="p-6 text-surface dark:text-white">
                <a href="#">
                  <h5 className="mb-2 text-xl font-medium leading-tight">
                    <b>{berita.nama}</b>
                  </h5>
                </a>

                <p className="mb-4 text-base">{berita.tanggal_upload}</p>
                <div
                  className="mb-4 text-base"
                  dangerouslySetInnerHTML={{ __html: berita.berita }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import TextField from "../../Component/TextField";
import Button from "../../Component/Button";
import MainContainer from "../../Component/MainContainer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Alert from "../../Component/Alert";
import { AnimatePresence } from "framer-motion";


function Message() {
  const [emailList, setEmailList] = useState({
    subject: "",
    body: "",
    background: "",
    color:""
  });
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const navigate = useNavigate();

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://alumni-be.fti.ukdw.ac.id/api/birthdays");
        setEmailList(response.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []); 

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          variant: "",
        });
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [alert.show]);

  const onSubmit = async (e) => {
    e.preventDefault();
  
    const formData = {
      subject: emailList && emailList.subject ? emailList.subject : "",
      body: emailList && emailList.body ? emailList.body : "",
      background: emailList && emailList.background ? emailList.background : "",
      color: emailList &&emailList.color ? emailList.color:""
    };
  
    try {
      const response = await axios.put("https://alumni-be.fti.ukdw.ac.id/api/birthdays/1", formData);
      console.log("Data updated successfully:", response.data);
      setAlert({
        show: true,
        message: "Birthday Message Berhasil diubah",
        variant: "primary",
      });
    } catch (error) {
      console.error("Error updating data:", error);
      setAlert({
        show: true,
        message: error.response.data.message,
        variant: 'error',
      });
    }
  };
  

  return (
    <MainContainer>
      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6`}
              variant={alert.variant}
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="mb-[4px]">
          <TextField
            id="subject"
            label="Subject"
            autoComplete="off"
            placeholder="Enter Subject"
            value={emailList.subject || ''}
            onChange={(e) => setEmailList({ ...emailList, subject: e.target.value })}
            className="w-[1142px]"
            type="text"
          />
        </div>
        <div className="mb-5">
          <p className="text-body-sm font-semibold lg:mb-1">Body</p>
          <ReactQuill
            id="body"
            theme="snow"
            className="h-[300px] mb-[50px]"
            value={emailList.body}
            onChange={(value) => setEmailList((prevState) => ({ ...prevState, body: value }))}
          />
        </div>
        <TextField
          id="background"
          label="Background Image"
          autoComplete="off"
          placeholder="Enter Background Link"
          value={emailList.background}
          onChange={(e) => setEmailList({ ...emailList, background: e.target.value })}
          className="w-[1142px]"
          type="text"
        />
        <TextField
        type="color"
          id="background"
          label="Background Color"
          autoComplete="off"
          placeholder="Enter Background Link"
          value={emailList.color}
          onChange={(e) => setEmailList({ ...emailList, color: e.target.value })}
          className="w-[11rem] h-[11rem]"
        />
        <div className="flex w-full justify-end items-center mt-[30px]">
          <Button
            id="save-article"
            type="submit"
            variant={"blue"}
            size="md"
            className={"rounded-full"}
          >
            Save
          </Button>
        </div>
      </form>
    </MainContainer>
  );
}

export default Message;

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import MainContainer from "../../../Component/MainContainer";
import Table from "../../../Component/Table";
import {
  Add20Regular,
  Edit20Regular,
  Eye20Regular,
  Delete20Regular,
} from "@fluentui/react-icons";
import Button from "../../../Component/Button";
import TextField from "../../../Component/TextField";
import PaginationButton from "../../../Component/PaginationButton";
import { ConfirmModal } from "../../../Component/Modal";
import Alert from "../../../Component/Alert";
import { AnimatePresence } from "framer-motion";

const ITEMS_PER_PAGE = 8;

export default function ManajemenBerita() {
  const [beritaData, setBeritaData] = useState([]);
  const [modalDelete, setModalDelete] = useState({ valid: false, id: null });

 
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "",
  });

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          variant: "",
        });
      }, 2500);
    }
    fetchData();
    return () => clearTimeout(timer);
  }, [alert.show]);

  const removeHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("https://alumni-be.fti.ukdw.ac.id/api/berita");
      setBeritaData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDeleteBerita = async (id) => {
    try {
      await axios.delete(`https://alumni-be.fti.ukdw.ac.id/api/berita/${id}`);
      setAlert({
        show: true,
        message: "Lowongan berhasil dihapus",
        variant: "error",
      });
      fetchData();
    }  catch (error) {
      console.log(error);
      setAlert({
        show: true,
        message: error.message || 'Gagal Hapus',
        variant: "error",
      });
    }
    setModalDelete({ valid: false, id: null });
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  return (
    <MainContainer>
      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6 mt-8`}
              variant={alert.variant}
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      <Link to="/admin/9" className="justify-self-end">
        <Button size="md" id="add-alumni">
          <Add20Regular className="mr-2" />
          Tambah
        </Button>
      </Link>
      <Table
        headers={["Gambar", "Nama", "Tanggal Upload", "Berita", "Aksi"]}
        className={
          "overflow-y-scroll mt-7 w-full overflow-x-hidden text-[#030712]"
        }
      >
        {/* Menampilkan data alumni dalam tabel */}
        {beritaData.map((berita) => (
          <tr className="text-center" key={berita.id}>
            <td className="relative">
              <img
                src={berita.gambar}
                alt={`Gambar Berita ${berita.nama}`}
                className="w-[80px] h-[80px] mx-auto"
                />
            </td>
            <td className="align-middle">{truncateText(berita.nama, 5)}</td>
            <td className="align-middle">{formatDate(berita.tanggal_upload)}</td>
            <td className="align-middle">
              {truncateText(removeHtmlTags(berita.berita), 5)}
            </td>
            <td>
              <div className="flex gap-3 justify-center">
                <Link to={`/admin/11/${berita.id}`}>
                  <Eye20Regular className="cursor-pointer hover:text-info" />
                </Link>
                <Delete20Regular
                  className="cursor-pointer hover:text-info"
                  onClick={() => {
                    setModalDelete({ valid: true, id: berita.id });
                  }}
                />
                <Link to={`/admin/10/${berita.id}`}>
                  <Edit20Regular className="cursor-pointer hover:text-info" />
                </Link>
              </div>
            </td>
          </tr>
        ))}
      </Table>

      {modalDelete.valid ? (
        <ConfirmModal
          title={"Konfirmasi Hapus Data Berita"}
          text={"Yakin ingin menghapus data Berita ini?"}
          cancelText={"Tidak"}
          confirmText={"Ya"}
          icon={"delete"}
          isOpen={modalDelete ? true : false}
          onCancel={() => {
            setModalDelete({ valid: false, id: null });
          }}
          onConfirm={() => {
            handleDeleteBerita(modalDelete.id);
          }}
        />
      ) : null}
    </MainContainer>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../Component/Button";
import TextField from "../../../Component/TextField";
import SecondaryContainer from "../../../Component/SecondaryContainer";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import { ConfirmModal } from "../../../Component/Modal";
import FileInput from "../../../Component/FileInput";
import Alert from "../../../Component/Alert";
import { AnimatePresence } from "framer-motion";

export default function EditBerita() {
  const [beritaList, setBeritaList] = useState({
    gambar: "",
    nama: "",
    tanggal_upload: "",
    berita: "",
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const param = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          variant: "",
        });
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [alert.show]);

  useEffect(() => {
    const fetchberita = async () => {
      try {
        const res = await axios.get(
          `https://alumni-be.fti.ukdw.ac.id/api/berita/${param.id}`
        );
        setBeritaList(res.data);
        console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchberita();
  }, [param.id]);

  const handleonClick = async (e) => {
    e.preventDefault();
    setShowConfirmModal(true);
  };

  const handleConfirmSave = async () => {
    const formData = new FormData();

    formData.append("nama", beritaList.nama);
    formData.append("tanggal_upload", beritaList.tanggal_upload);
    formData.append("berita", beritaList.berita);

    formData.append("gambar", beritaList.gambar);

    try {
      const res = await axios.post(
        `https://alumni-be.fti.ukdw.ac.id/api/berita/update/${param.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setAlert({
        show: true,
        message: "Lowongan Berhasil diubah",
        variant: "primary",
      });
      setTimeout(() => {
        navigate("/admin/7");
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        show: true,
        message: error.message||"data gagal di update"||error.response.data.message,
        variant: "error",
      });
    }
    setShowConfirmModal(false);
  };

  return (
    <SecondaryContainer
      backTo="/admin/7"
      title="Edit Data Berita"
      className={"pe-3"}
    >
      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6 mt-8`}
              variant={alert.variant}
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      <form onSubmit={(e) => e.preventDefault()} className="px-8">
        <div>
          {beritaList.gambar && (
            <img
              src={beritaList.gambar}
              alt="Foto Alumni"
              className="w-[100px] h-[100px]"
            />
          )}
          <FileInput
            type="file"
            id="gambar"
            label="Gambar"
            name="gambar"
            value={beritaList.gambar}
            onChange={(file) => setBeritaList({ ...beritaList, gambar: file })}
          />
        </div>
        <div>
          <TextField
            id="namBerita"
            label="Nama Berita"
            autoComplete="off"
            placeholder="Tulis Nama Berita"
            value={beritaList.nama}
            onChange={(e) =>
              setBeritaList({ ...beritaList, nama: e.target.value })
            }
            className="w-[1142px]"
          ></TextField>
        </div>
        <div>
          <TextField
            type="date"
            id="tanggalBerita"
            label="Tanggal Acara"
            autoComplete="off"
            placeholder="Tulis Tanggal Acara"
            value={beritaList.tanggal_upload}
            onChange={(e) =>
              setBeritaList({ ...beritaList, tanggal_upload: e.target.value })
            }
            className="w-[1142px]"
          ></TextField>
        </div>
        <div className="mb-5">
          <p className="text-body-sm font-semibold lg:mb-1">Berita</p>
          <ReactQuill
            id="dataBerita"
            theme="snow"
            className="h-[200px] mb-[50px] rounded"
            value={beritaList.berita}
            onChange={(value) =>
              setBeritaList((prevState) => ({ ...prevState, berita: value }))
            }
          />
        </div>
        <div>
          <Button type="button" onClick={handleonClick}>
            Simpan
          </Button>
        </div>
      </form>

      {showConfirmModal && (
        <ConfirmModal
          title={"Konfirmasi Simpan Data Berita"}
          text={"Apakah Anda yakin ingin mengubah data Berita ini?"}
          cancelText={"Batal"}
          confirmText={"Simpan"}
          isOpen={showConfirmModal}
          onCancel={() => {
            setShowConfirmModal(false);
          }}
          onConfirm={() => {
            handleConfirmSave();
          }}
        />
      )}
    </SecondaryContainer>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../Component/Button";
import TextField from "../../../Component/TextField";
import SecondaryContainer from "../../../Component/SecondaryContainer";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ConfirmModal } from "../../../Component/Modal";
import FileInput from "../../../Component/FileInput";
import Alert from "../../../Component/Alert";
import { AnimatePresence } from "framer-motion";
export default function EditDB() {
  const navigate = useNavigate();
  const param = useParams();

  const [alumniData, setAlumniData] = useState({
    nama: "",
    email: "",
    nim: "",
    tanggal_lahir: "",
    lulus: "",
    agama: "",
    foto: null,
    linkedin: "",
    tahun_angkatan: "",
    jenis_kelamin: "",
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "",
  });

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          variant: "",
        });
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [alert.show]);

  useEffect(() => {
    const fetchAlumniDetail = async () => {
      try {
        const response = await axios.get(
          `https://alumni-be.fti.ukdw.ac.id/api/alumnus/${param.nim}`
        );
        console.log(response.data);
        setAlumniData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAlumniDetail();
  }, [param.nim]);

  const handleonClick = async (e) => {
    e.preventDefault();
    setShowConfirmModal(true);
  };

  const handleConfirmSave = async () => {
    const formData = new FormData();
    formData.append("nama", alumniData.nama);
    formData.append("email", alumniData.email);
    formData.append("nim", alumniData.nim);
    formData.append("tanggal_lahir", alumniData.tanggal_lahir);
    formData.append("lulus", alumniData.lulus);
    formData.append("agama", alumniData.agama);
    formData.append("foto", alumniData.foto);
    formData.append("linkedin", alumniData.linkedin);
    formData.append("tahun_angkatan", alumniData.tahun_angkatan);
    formData.append("jenis_kelamin", alumniData.jenis_kelamin);
    formData.append("telepon", alumniData.telepon);
    formData.append("nama_perusahaan", alumniData.nama_perusahaan);
    formData.append("nama_atasan", alumniData.nama_atasan);
    formData.append("telepon_atasan", alumniData.telepon_atasan);
    formData.append("email_atasan", alumniData.email_atasan);
    formData.append("alamat_perusahaan", alumniData.alamat_perusahaan);
    formData.append("posisi", alumniData.posisi);
    formData.append("skala_perusahaan", alumniData.skala_perusahaan);

    try {
      const response = await axios.post(
        `https://alumni-be.fti.ukdw.ac.id/api/alumnus/update/${alumniData.id_mahasiswa}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Data updated successfully:", response.data);
      setAlert({
        show: true,
        message: "Data Berhasil diedit",
        variant: "primary",
      });
      setTimeout(() => {
        navigate("/admin/alumni");
      }, 3000);
    } catch (error) {
      console.error("Error updating data:", error);
      setAlert({
        show: true,
        message: error.response.data.message,
        variant: "error",
      });
    }
    setShowConfirmModal(false);
  };
  return (
    <SecondaryContainer
      backTo="/admin/alumni"
      title="Edit Data"
      className={"pe-3"}
    >
      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6 mt-8`}
              variant={alert.variant}
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div>
          {alumniData.foto ? (
            <img
              src={alumniData.foto}
              alt="Foto Alumni"
              className="w-[100px] h-[100px]"
            />
          ) : (
            <div className="w-[80px] h-[80px] bg-gray-200  flex items-center justify-center text-gray-500 text-xl">
              {alumniData.nama.charAt(0)}
            </div>
          )}
          <FileInput
            type="file"
            id="foto"
            label="Foto"
            name="foto"
            onChange={(file) => setAlumniData({ ...alumniData, foto: file })}
          />
        </div>
        <div>
          <TextField
            id="namaAlumni"
            label="Nama Alumni"
            autoComplete="off"
            placeholder="Masukkan Nama Alumni"
            onChange={(e) =>
              setAlumniData({ ...alumniData, nama: e.target.value })
            }
            value={alumniData.nama}
          ></TextField>
        </div>

        <div>
          <TextField
            id="emailAlumni"
            label="Email Alumni"
            autoComplete="off"
            placeholder="Masukkan Email Alumni"
            value={alumniData.email}
            onChange={(e) =>
              setAlumniData({ ...alumniData, email: e.target.value })
            }
          ></TextField>
        </div>

        <div>
          <TextField
            id="nim"
            label="NIM"
            autoComplete="off"
            placeholder="Masukkan NIM"
            value={alumniData.nim}
            onChange={(e) =>
              setAlumniData({ ...alumniData, nim: e.target.value })
            }
          ></TextField>
        </div>

        <div className="">
          <TextField
            id="tanggal_lahir"
            label="Tanggal Lahir"
            autoComplete="off"
            placeholder="Tanggal Lahir"
            value={alumniData.tanggal_lahir}
            onChange={(e) =>
              setAlumniData({ ...alumniData, tanggal_lahir: e.target.value })
            }
            className="w-[1142px]"
            type="date"
          ></TextField>
        </div>

        <div className="">
          <TextField
            id="lulus"
            label="Tahun Lulus"
            autoComplete="off"
            placeholder="Masukkan Tahun Lulus"
            value={alumniData.lulus}
            onChange={(e) =>
              setAlumniData({ ...alumniData, lulus: e.target.value })
            }
            className="w-[1142px]"
            type="date"
          ></TextField>
        </div>

        <div className="">
          <TextField
            id="tahun_angkatan"
            label="Tahun Angkatan"
            autoComplete="off"
            placeholder=""
            value={alumniData.tahun_angkatan}
            onChange={(e) =>
              setAlumniData({ ...alumniData, tahun_angkatan: e.target.value })
            }
            className="w-[1142px]"
          ></TextField>
        </div>
        <div className="">
          <TextField
            id="linkedin"
            label="Linkedin Alumni"
            autoComplete="off"
            placeholder="Tulis Linkedin Alumni"
            value={alumniData.linkedin}
            onChange={(e) =>
              setAlumniData({ ...alumniData, linkedin: e.target.value })
            }
            className="w-[1142px]"
          ></TextField>
        </div>

        <label className="text-body-sm font-semibold">Agama</label>
        <div>
          <select
            value={alumniData.agama}
            name="Agama"
            onChange={(e) =>
              setAlumniData({ ...alumniData, agama: e.target.value })
            }
            className="border w-full h-[40px] mb-[10px] rounded-[4px]"
          >
            <option value="0"></option>
            <option value="1">Kristen</option>
            <option value="2">Katolik</option>
            <option value="3">Islam</option>
            <option value="4">Hindu</option>
            <option value="5">Budha</option>
            <option value="6">Konghucu</option>
          </select>
        </div>

        <div>
          <label className="text-body-sm font-semibold">Gender</label>

          <select
            value={alumniData.jenis_kelamin}
            name="Gender"
            onChange={(e) =>
              setAlumniData({ ...alumniData, jenis_kelamin: e.target.value })
            }
            className="border w-full h-[40px] rounded-[4px] mb-[10px]"
          >
            <option value="0"></option>
            <option value="L">Laki - Laki</option>
            <option value="P">Perempuan</option>
          </select>
        </div>

        {/* Pekerjaan*/}
        <div className="">
          <TextField
            id="telepon"
            label="Nomor Telepon"
            autoComplete="off"
            placeholder="Tulis Nomer Telepon"
            value={alumniData.telepon}
            onChange={(e) =>
              setAlumniData({ ...alumniData, telepon: e.target.value })
            }
            className="w-[1142px]"
          ></TextField>
        </div>
        <div className="">
          <TextField
            id="nama_perusahaan"
            label="Nama Perusahaan"
            autoComplete="off"
            placeholder="Tulis nama perusahaan"
            value={alumniData.nama_perusahaan}
            onChange={(e) =>
              setAlumniData({ ...alumniData, nama_perusahaan: e.target.value })
            }
            className="w-[1142px]"
          ></TextField>
        </div>
        <div className="">
          <TextField
            id="alamat_perusahaan"
            label="Alamat Perusahaan"
            autoComplete="off"
            placeholder="Tulis alamat perusahaan"
            value={alumniData.alamat_perusahaan}
            onChange={(e) =>
              setAlumniData({ ...alumniData, alamat_perusahaan: e.target.value })
            }
            className="w-[1142px]"
          ></TextField>
        </div>
        <div className="">
          <TextField
            id="nama_atasan"
            label="Nama Atasan"
            autoComplete="off"
            placeholder="Tulis nama atasan"
            value={alumniData.nama_atasan}
            onChange={(e) =>
              setAlumniData({ ...alumniData, nama_atasan: e.target.value })
            }
            className="w-[1142px]"
          ></TextField>
        </div>
        <div className="">
          <TextField
            id="telepon_atasan"
            label=" Nomor Telepon Atasan"
            autoComplete="off"
            placeholder="Tulis telepon atasan"
            value={alumniData.telepon_atasan}
            onChange={(e) =>
              setAlumniData({ ...alumniData, telepon_atasan: e.target.value })
            }
            className="w-[1142px]"
          ></TextField>
        </div>
        <div className="">
          <TextField
            id="email_atasan"
            label="Email Atasan"
            autoComplete="off"
            placeholder="Tulis email atasan"
            value={alumniData.email_atasan}
            onChange={(e) =>
              setAlumniData({ ...alumniData, email_atasan: e.target.value })
            }
            className="w-[1142px]"
          ></TextField>
        </div>
        <div className="">
          <TextField
            id="posisi"
            label="Posisi Pekerjaan"
            autoComplete="off"
            placeholder="Tulis posisi pekerjaan"
            value={alumniData.posisi}
            onChange={(e) =>
              setAlumniData({ ...alumniData, posisi: e.target.value })
            }
            className="w-[1142px]"
          ></TextField>
        </div>

        <label className="text-body-sm font-semibold">Skala Perusahaan</label>
        <div>
          <select
            value={alumniData.skala_perusahaan}
            name="Skala Perusahaan"
            onChange={(e) =>
              setAlumniData({ ...alumniData, skala_perusahaan: e.target.value })
            }
            className="border w-full h-[40px] mb-[10px] rounded-[4px]"
          >
            <option value=""></option>
            <option value="Lokal">Lokal</option>
            <option value="Nasional">Nasional</option>
            <option value="Internasional">Internasional</option>
          </select>
        </div>

        <div className="flex w-full justify-end items-center">
          <Button
            id="save-article"
            type="submit"
            variant={"blue"}
            size="md"
            className={"rounded-full"}
            onClick={handleonClick}
          >
            Simpan
          </Button>
        </div>
      </form>

      {showConfirmModal && (
        <ConfirmModal
          title={"Konfirmasi Edit Data"}
          text={"Apakah Anda yakin ingin mengubah data alumni ini?"}
          cancelText={"Batal"}
          confirmText={"Simpan"}
          isOpen={showConfirmModal}
          onCancel={() => {
            setShowConfirmModal(false);
          }}
          onConfirm={() => {
            handleConfirmSave();
          }}
        />
      )}
    </SecondaryContainer>
  );
}

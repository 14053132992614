import { Navigate} from "react-router-dom";
import { useLocation } from "react-router-dom"; 

import Cookies from "js-cookie";

const ProtectedLoginRoute = ({ component: Component,nim, ...rest }) => {
  const token = Cookies.get("token");
  const role = Cookies.get("role")

  const location = useLocation();

  if (role =='alumni') {

      <Navigate
        to={`/${nim}`}
        replace
      />

  }else{
    <Navigate
        to="/login"
        replace
      />
  }

  return <Component {...rest} />;
};

export default ProtectedLoginRoute;

import {
  ChevronRight20Filled,
  Grid20Regular,
  Mail20Regular,
  WeatherCloudy20Regular,
  FoodCake20Regular,
  News20Regular,
  ChatHelp20Regular,
  Database20Regular
} from "@fluentui/react-icons";
import logo from '../assets/logoo.png'
import { useEffect, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
export default function Sidebar({children}) {
  const { pathname } = useLocation();
  const itemListRef = useRef(null);

  useEffect(() => {
    itemListRef.current.childNodes.forEach((item) => {
      if (
        pathname.includes(item.childNodes[0].pathname) &&
        item.childNodes[0].pathname !== "/admin" &&
        item.childNodes[0].pathname !== "/admin/"
      ) {
        item.childNodes[0].classList.add("active");
      } else if (
        item.childNodes[0].pathname === "/admin" &&
        pathname === "/admin"
      ) {
        item.childNodes[0].classList.add("active");
      } else {
        item.childNodes[0].classList.remove("active");
      }
    });
  }, [pathname]);

  return (
    <div className="drawer drawer-mobile" >
      <input
        id="my-drawer-2"
        type="checkbox"
        className="drawer-toggle"
      />
      <div
        style={{
          zIndex: 20,
        }}
        className="drawer-content flex flex-col items-start justify-start"
      >
        {/* <!-- Page content here --> */}
        <label
          htmlFor="my-drawer-2"
          className="btn btn-primary text-neutral-10 absolute -left-1 top-20 rounded-e-[41px] justify-end drawer-button lg:hidden"
        >
          <ChevronRight20Filled />
        </label>
        {children}
      </div>
      <div className="w-[280px] h-auto pb-[400px]  shadow-elevation-1">
      <label
          htmlFor="my-drawer-2"
          className="drawer-overlay"
        ></label>
        <ul className="menu pt-14 bg-base-100 text-base-content">
          {/* <!-- Sidebar content here --> */}
          <a href={"/"} className="self-center">
          <img
            src={logo}
            alt="Logo Admin"
            className="w-[150px]"
          />
          </a>
          
          
          <ul
            className="mt-14 text-neutral-70 text-body-sm"
            ref={itemListRef}
          >
            <li>
              <Link
                className="px-8 py-4 hover:bg-primary-surface"
                to={"/admin/kalender"}
              >
                <Grid20Regular /> Overview
              </Link>
            </li>
            <li>
              <Link
                className="px-8 py-4 hover:bg-primary-surface"
                to={"/admin/alumni"}
              >
                <Database20Regular /> Alumni Management
              </Link>
            </li>
            <li>
              <Link
                className="px-8 py-4 hover:bg-primary-surface"
                to={"/admin/6"}
              >
                <Mail20Regular />
                Email
              </Link>
            </li>
            <li>
              <Link
                className="px-8 py-4 hover:bg-primary-surface"
                to={"/admin/3"}
              >
                <FoodCake20Regular />
                Birthday Message
              </Link>
            </li>
            <li>
              <Link
                className="px-8 py-4 hover:bg-primary-surface"
                to={"/admin/7"}
              >
                <News20Regular />
                Job Management
              </Link>
            </li>
          </ul>
        </ul>
      </div>
    </div>
  );
}

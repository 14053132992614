import Table from "../../Component/Table";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Add20Regular,
  Edit20Regular,
  Eye20Regular,
  Delete20Regular,
} from "@fluentui/react-icons";
import SecondaryContainer from "../../Component/SecondaryContainer";
import axios from "axios";
import { ConfirmModal } from "../../Component/Modal";
import { AnimatePresence } from "framer-motion";
import Alert from "../../Component/Alert";


export default function AllEvents() {
  const [events, setEvents] = useState([]);
  const [showDelete, setShowDelete] = useState({ valid: false, id: null });

  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const navigate = useNavigate()

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          variant: "",
        });
      }, 2500);
    }
    fetchData();
    return () => clearTimeout(timer);
  }, [alert.show]);
  const fetchData = async () => {
    try {
      const response = await fetch("https://alumni-be.fti.ukdw.ac.id/api/events");
      const eventData = await response.json();

      setEvents(eventData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (eventId) => {
    try {
      await axios.delete(`https://alumni-be.fti.ukdw.ac.id/api/events/${eventId}`);
      setAlert({
        show: true,
        message: "Event Berhasil Dihapus",
        variant: "error",
      });
      setTimeout(() => {
        navigate("/admin/kalender");
      }, 3000);
      fetchData();
    } catch (error) {
      console.log(error);
    }
    setShowDelete({ valid: false, id: null });
  };

  const removeHtmlTags = (text) => {
    return text ? text.replace(/<\/?[^>]+(>|$)/g, "") : "";
  };

  return (
    <SecondaryContainer backTo="/admin/kalender" title="All Event" className={"pe-10"}>
      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6`}
              variant={alert.variant}
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      {showDelete.valid ? (
        <ConfirmModal
          title={"Konfirmasi Hapus Data Event"}
          text={"Yakin ingin menghapus data event ini?"}
          cancelText={"Tidak"}
          confirmText={"Ya"}
          icon={"delete"}
          isOpen={showDelete ? true : false}
          onCancel={() => {
            setShowDelete({ valid: false, id: null });
          }}
          onConfirm={() => {
            handleDelete(showDelete.id);
          }}
        />
      ) : null}

      <Table
        headers={["No", "Title", "Date", "Subject", "Body", "Aksi"]}
        className={
          "overflow-y-scroll w-full ml-[20px] overflow-x-hidden text-[#030712]"
        }
      >
        {events.map((event, index) => {
          const kata = event.body.split(" ");
          const maxKata = kata.slice(0, 7).join(" ");
          const Desc = kata.length > 7 ? maxKata + "..." : maxKata;
          return (
            <tr key={event.title} className="text-center">
              <td className="pb-[5px]">{index + 1}</td>
              <td className="pb-[5px]">{event.title}</td>
              <td className="pb-[5px]">{event.start}</td>
              <td className="pb-[5px]">{event.subject}</td>
              <td className="pb-[5px]">{removeHtmlTags(Desc)}</td>{" "}
              <td className="pb-[5px]">
                <div className="flex gap-3 justify-center">
                <Link to={`/admin/15/${event.id}`}>
                    <Edit20Regular className="cursor-pointer" />
                  </Link>
                  <Delete20Regular
                    className="cursor-pointer hover:text-info"
                    onClick={() => setShowDelete({ valid: true, id: event.id })}
                  />
                </div>
              </td>
            </tr>
          );
        })}
      </Table>
    </SecondaryContainer>
  );
}

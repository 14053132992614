import { useState , useEffect} from "react";
import MainContainer from "../../Component/MainContainer";
import TextField from "../../Component/TextField";
import axios from "axios";
import Cookies from "js-cookie";
import Button from "../../Component/Button";
import { useNavigate } from "react-router-dom";
import AlumniContainer from "../../Component/Alumni/AlumniContainer";
import Alert from "../../Component/Alert";
import { AnimatePresence } from "framer-motion";

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "",
  });

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          variant: "",
        });
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [alert.show]);

  const navigate = useNavigate();

  const handleResetPassword = async (event) => {
    event.preventDefault();

    try {
      const token = Cookies.get("token");

      if (!token) {
        throw new Error("Token not found");
      }

      const response = await axios.post(
        "https://alumni-be.fti.ukdw.ac.id/api/reset-password",
        {
          password: password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Password reset successfully");

      if (response.data.redirect == "reset_password") {
        navigate(`/reset`);
      } else {
      }

      setAlert({
        show: true,
        message: "Password Reset Successfully",
        variant: "primary",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setAlert({
        show: true,
        message: "Password Reset Unsuccessfully",
        variant: "error",
      });
    }
  };

  return (
    <AlumniContainer>
      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6`}
              variant={alert.variant}
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      <div>
        <TextField
          type="password"
          id="password"
          label="New Password"
          autoComplete="off"
          placeholder="New Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          className="w-[1142px]"
        />
      </div>
      <div className="flex justify-end">
        <Button size="md" id="reset-password" onClick={handleResetPassword}>
          Reset Password
        </Button>
      </div>
    </AlumniContainer>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const DetailDB = () => {
  const [alumni, setAlumni] = useState({});
  const param = useParams();

  useEffect(() => {
    const fetchAlumniDetail = async () => {
      try {
        const response = await axios.get(`https://alumni-be.fti.ukdw.ac.id/api/alumnus/${param.nim}`);
        console.log(response.data);
        setAlumni(response.data);
    } catch (error) {
        console.log(error);
      }
    };
  
    fetchAlumniDetail();
  }, [param.nim]);
  

  return (
    <div>
      <h2>Detail Alumni</h2>
      <img className="w-[80px] h-[80px]" src={alumni.foto} alt={`Foto ${alumni.foto}`} />
      <p>Nama: {alumni.nama}</p>
      <p>NIM: {alumni.nim}</p>
      <p>Email: {alumni.email}</p>
      <p>Tanggal Lahir: {alumni.tanggal_lahir}</p>
      <p>Lulus: {alumni.lulus}</p>
      <p>Linkedin: {alumni.linkedin}</p>
    </div>
  );
};

export default DetailDB;

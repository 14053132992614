import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import MainContainer from "../../Component/MainContainer";
import Button from "../../Component/Button";
import axios from "axios";
import {
  Delete20Regular,
  Edit20Regular,
  Info12Regular,
  Add20Regular,
} from "@fluentui/react-icons";
import TextField from "../../Component/TextField";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import FileInput from "../../Component/FileInput";
import { ConfirmModal } from "../../Component/Modal";
import { AnimatePresence } from "framer-motion";
import Alert from "../../Component/Alert";

const localizer = momentLocalizer(moment);

const Calendarr = () => {
  const [isModalEditOpen, setModalEditOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [tittle, settittle] = useState("");
  const [startEvent, setStartEvent] = useState();
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [attach, setAttach] = useState(null);
  const [background, setBackground] = useState("");
  const [agama, setAgama] = useState();
  const [color, setColor] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editStartEvent, setEditStartEvent] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [editSubject, setEditSubject] = useState("");
  const [editBody, setEditBody] = useState("");
  const [editBackground, setEditBackground] = useState("");
  const [editAgama, setEditAgama] = useState();
  const [editColor, setEditColor] = useState("");
  const [showModalDelete, setShowModalDelete] = useState({
    valid: false,
    id: null,
  });
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "",
  });

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          variant: "",
        });
      }, 2500);
    }
    fetchData();
    return () => clearTimeout(timer);
  }, [alert.show]);

  const fetchData = async () => {
    try {
      const response = await fetch("https://alumni-be.fti.ukdw.ac.id/api/events");
      const eventData = await response.json();

      setEvents(eventData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleonClick = async (event) => {
    event.preventDefault();

    try {
      if (isEditMode) {
        await axios.put(
          `https://alumni-be.fti.ukdw.ac.id/api/events/${selectedEvent.id}`,
          {
            title: editTitle,
            start: editStartEvent,
            subject: editSubject,
            body: editBody,
            background: editBackground,
            agama: editAgama,
            color: editColor,
          }
        );
        fetchData();
        setEditMode(false);
      } else {
        console.log("asasas");
      }
      fetchData();
      setEditTitle("");
      setEditStartEvent(null);
      setModalEditOpen(false);

      alert(
        isEditMode ? "Data updated successfully!" : "Data saved successfully!"
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = (event) => {
    openEditModal(event);
  };

  const openEditModal = (event) => {
    setEditTitle(event.title);
    setEditStartEvent(event.start);
    setSelectedEvent(event);
    setEditSubject(event.subject);
    setEditBody(event.body);
    setEditBackground(event.background);
    setEditAgama(event.agama);
    setEditColor(event.color);
    setEditMode(true);
    setModalEditOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalEditOpen(false);
  };

  const handleDelete = async (eventId) => {
    try {
      await axios.delete(`https://alumni-be.fti.ukdw.ac.id/api/events/${eventId}`);
      setAlert({
        show: true,
        message: "Event Berhasil Dihapus",
        variant: "error",
      });
      fetchData();
    } catch (error) {
      console.log(error);
    }
    setShowModalDelete({ valid: false, id: null });
  };

  const CustomAgendaEvent = ({ event }) => {
    return (
      <div className="flex items-center justify-between">
        <span>{event.title}</span>
        <div className="flex space-x-2">
        <Link to={`/admin/15/${event.id}`}>
            <Edit20Regular
              className="cursor-pointer"
              // onClick={() => handleEdit(event)}
            />
          </Link>
          <Delete20Regular
            className="cursor-pointer"
            onClick={() => {
              setShowModalDelete({ valid: true, id: event.id });
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <MainContainer>
      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6`}
              variant={alert.variant}
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      <Link to={`/admin/kalender/1`}>
        <Button size="md" id="add-article">
          Add Event
        </Button>
      </Link>
      <Link to={`/admin/kalender/events`}>
        <Button size="md" id="add-article" className="ml-[10px]">
          All Event
        </Button>
      </Link>

      {showModalDelete.valid ? (
        <ConfirmModal
          title={"Konfirmasi Hapus Data Event"}
          text={"Yakin ingin menghapus data Event ini?"}
          cancelText={"Tidak"}
          confirmText={"Ya"}
          icon={"delete"}
          isOpen={showModalDelete ? true : false}
          onCancel={() => {
            setShowModalDelete({ valid: false, id: null });
          }}
          onConfirm={() => {
            handleDelete(showModalDelete.id);
          }}
        />
      ) : null}

      {/* Edit Modal */}
      {isModalEditOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-black bg-opacity-50">
          <div className="relative w-[900px]  p-6 bg-white rounded-md">
            {/* Konten modal */}
            <div className="flex justify-between mb-[5px]">
              <h3 className="text-xl font-semibold">Event Edit</h3>
              <button
                className="bg-transparent border-0 text-black"
                onClick={() => {
                  closeModal();
                }}
              >
                <span className="text-black text-3xl">&times;</span>
              </button>
            </div>

            <form>
              <div className="mb-4">
                <label
                  htmlFor="eventName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Event Name:
                </label>
                <input
                  type="text"
                  id="eventName"
                  name="eventName"
                  value={isEditMode ? editTitle : tittle}
                  onChange={
                    isEditMode
                      ? (event) => setEditTitle(event.target.value)
                      : (event) => settittle(event.target.value)
                  }
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="eventDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Start Date:
                </label>
                <input
                  type="datetime-local"
                  id="eventDate"
                  name="start"
                  value={
                    isEditMode
                      ? moment(editStartEvent).format("YYYY-MM-DDTHH:mm")
                      : startEvent
                  }
                  onChange={
                    isEditMode
                      ? (event) => setEditStartEvent(event.target.value)
                      : (event) => setStartEvent(event.target.value)
                  }
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div>
                <TextField
                  type="text"
                  id="subject"
                  label="Subject"
                  autoComplete="off"
                  placeholder="Subject"
                  value={isEditMode ? editSubject : subject}
                  onChange={
                    isEditMode
                      ? (event) => setEditSubject(event.target.value)
                      : (event) => setSubject(event.target.value)
                  }
                  className="w-[1142px]"
                />
              </div>
              <div className="mb-5">
                <p className="text-body-sm font-semibold lg:mb-1">Body</p>
                <ReactQuill
                  id="body"
                  theme="snow"
                  className="h-[100px] mb-[50px] rounded"
                  value={isEditMode ? editBody : body}
                  onChange={isEditMode ? setEditBody : setBody}
                />
              </div>
              <div>
                <TextField
                  type="text"
                  id="background"
                  label="Background"
                  autoComplete="off"
                  placeholder="Background Link"
                  value={isEditMode ? editBackground : background}
                  onChange={
                    isEditMode
                      ? (event) => setEditBackground(event.target.value)
                      : (event) => setBackground(event.target.value)
                  }
                  className="w-[1142px]"
                />
              </div>
              <label htmlFor="">Kategori</label>
              <div>
                <select
                  value={isEditMode ? editAgama : agama}
                  name="Agama"
                  onChange={
                    isEditMode
                      ? (event) => setEditAgama(event.target.value)
                      : (event) => setAgama(event.target.value)
                  }
                  className="border"
                >
                  <option value="0"></option>
                  <option value="1">Kristen</option>
                  <option value="2">Katolik</option>
                  <option value="3">Islam</option>
                  <option value="4">Hindu</option>
                  <option value="5">Budha</option>
                  <option value="6">Konghucu</option>
                  <option value="7">All</option>
                </select>
              </div>
              <div>
                <TextField
                  type="color"
                  id="color"
                  label="Template Color"
                  autoComplete="off"
                  placeholder="Enter Template Color"
                  value={isEditMode ? editColor : color}
                  onChange={
                    isEditMode
                      ? (event) => setEditColor(event.target.value)
                      : (event) => setColor(event.target.value)
                  }
                  className="w-[11rem] h-[11rem]"
                />
              </div>
              <div>
                <FileInput
                  type="file"
                  id="attach"
                  label="Attach"
                  name="attach"
                  onChange={(file) => setAttach(file.target.files[0])}
                />
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleonClick}
                >
                  Save Event
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 ">
          <div className="relative w-[900px]  p-6 bg-white rounded-md">
            <div className="flex justify-between mb-[5px]">
              <h3 className="text-xl font-semibold">Event Details</h3>
              <button
                className="bg-transparent border-0 text-black"
                onClick={() => {
                  closeModal();
                }}
              >
                <span className="text-black text-3xl">&times;</span>
              </button>
            </div>

            <form>
              <div className="">
                <TextField
                  type="text"
                  id="eventName"
                  label="Event Name"
                  autoComplete="off"
                  placeholder="Subject"
                  value={tittle}
                  onChange={(event) => settittle(event.target.value)}
                  className="w-[1142px]"
                />
              </div>

              <div className="">
                <TextField
                  type="datetime-local"
                  id="eventDate"
                  label="Event Date"
                  autoComplete="off"
                  placeholder=""
                  value={startEvent}
                  onChange={(event) => setStartEvent(event.target.value)}
                  className="w-[1142px]"
                />
              </div>
              <div>
                <TextField
                  type="text"
                  id="subject"
                  label="Subject"
                  autoComplete="off"
                  placeholder="Subject"
                  value={subject}
                  onChange={(event) => setSubject(event.target.value)}
                  className="w-[1142px]"
                />
              </div>
              <div className="mb-5">
                <p className="text-body-sm font-semibold lg:mb-1">Body</p>
                <ReactQuill
                  id="body"
                  theme="snow"
                  className="h-[100px] mb-[50px] rounded"
                  value={body}
                  onChange={setBody}
                />
              </div>
              <div>
                <TextField
                  type="text"
                  id="background"
                  label="Background"
                  autoComplete="off"
                  placeholder="Background Link"
                  value={background}
                  onChange={(event) => setBackground(event.target.value)}
                  className="w-[1142px]"
                />
              </div>
              <label className="text-body-sm font-semibold">Kategori</label>
              <div>
                <select
                  value={agama}
                  name="Agama"
                  onChange={(event) => setAgama(event.target.value)}
                  className="border w-[30rem] h-[30px]"
                >
                  <option value="0"></option>
                  <option value="1">Kristen</option>
                  <option value="2">Katolik</option>
                  <option value="3">Islam</option>
                  <option value="4">Hindu</option>
                  <option value="5">Budha</option>
                  <option value="6">Konghucu</option>
                  <option value="7">All</option>
                </select>
              </div>
              <div>
                <TextField
                  type="color"
                  id="color"
                  label="Template Color"
                  autoComplete="off"
                  placeholder="Enter Template Color"
                  value={color}
                  onChange={(event) => setColor(event.target.value)}
                  className="w-[11rem] h-[11rem]"
                />
              </div>
              <div>
                <FileInput
                  type="file"
                  id="attach"
                  label="Attach"
                  name="attach"
                  onChange={(file) => setAttach(file)}
                />
              </div>
              <div className="flex justify-end">
                <Button size="md" id="add-alumni" onClick={handleAdd}>
                  Save Event
                </Button>
              </div>
            </form>
          </div>
        </div>
      )} */}

      <div className="w-[1300px] mt-[20px]">
        <Calendar
          localizer={localizer}
          startAccessor="start"
          endAccessor="start"
          events={events}
          style={{ height: 500 }}
          views={{
            month: true,
            week: false,
            day: false,
            agenda: true,
          }}
          components={{
            agenda: {
              event: CustomAgendaEvent,
            },
          }}
        />
      </div>
    </MainContainer>
  );
};

export default Calendarr;

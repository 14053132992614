import React, { useState, useEffect } from "react";
import SecondaryContainer from "../../Component/SecondaryContainer";
import Button from "../../Component/Button";
import axios from "axios";
import TextField from "../../Component/TextField";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import FileInput from "../../Component/FileInput";
import { AnimatePresence } from "framer-motion";
import Alert from "../../Component/Alert";
import { Info12Regular } from "@fluentui/react-icons";

export default function CreateEvent() {
  const [title, setTitle] = useState("");
  const [startEvent, setStartEvent] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [background, setBackground] = useState("");
  const [agama, setAgama] = useState("");
  const [color, setColor] = useState("");
  const [attach, setAttach] = useState("");
  const [previewData, setPreviewData] = useState(null);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          variant: "",
        });
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [alert.show]);

  const validate = () => {
    const newErrors = {};
    if (!title) newErrors.title = "Judul event tidak boleh kosong";
    if (!startEvent) newErrors.startEvent = "Tanggal event tidak boleh kosong";
    if (!subject) newErrors.subject = "Subjek tidak boleh kosong";
    if (!body) newErrors.body = "Isi event tidak boleh kosong";
    if (!background) newErrors.background = "Background tidak boleh kosong";
    if (!agama) newErrors.agama = "Kategori tidak boleh kosong";
    if (!color) newErrors.color = "Color harus dipilih";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePreview = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    try {
      const previewFormData = new FormData();
      previewFormData.append("title", title);
      previewFormData.append("start", startEvent);
      previewFormData.append("subject", subject);
      previewFormData.append("body", body);
      previewFormData.append("background", background);
      previewFormData.append("agama", agama);
      previewFormData.append("color", color);
      previewFormData.append("attach", attach);

      setPreviewData(previewFormData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAdd = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    try {
      if (previewData) {
        await axios.post("https://alumni-be.fti.ukdw.ac.id/api/events", previewData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        setPreviewData(null);
        setAlert({
          show: true,
          message: "Event Berhasil ditambah",
          variant: "primary",
        });
        setTimeout(() => {
          navigate("/admin/kalender");
        }, 3000);
      } else {
        console.log("Data belum di-preview!");
        setAlert({
          show: true,
          message: "Data belum di-preview!",
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setAlert({
        show: true,
        message: "Event gagal ditambah",
        variant: "error",
      });
    }
  };

  return (
    <SecondaryContainer
      title="Tambah Event"
      className={"pe-3"}
      backTo="/admin/kalender"
    >
      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6`}
              variant={alert.variant}
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      <form>
        <div className="">
          <TextField
            type="text"
            id="eventName"
            label="Event Name"
            autoComplete="off"
            placeholder="Event Name"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            isError={!!errors.title}
            message={
              errors.title && (
                <span>
                  <Info12Regular className="-mt-0.5" /> {errors.title}
                </span>
              )
            }
            className="w-[1142px]"
          />
        </div>

        <div className="">
          <TextField
            type="date"
            id="eventDate"
            label="Event Date"
            autoComplete="off"
            placeholder=""
            value={startEvent}
            onChange={(event) => setStartEvent(event.target.value)}
            isError={!!errors.startEvent}
            message={
              errors.startEvent && (
                <span>
                  <Info12Regular className="-mt-0.5" /> {errors.startEvent}
                </span>
              )
            }
            className="w-[1142px]"
          />
        </div>
        <div>
          <TextField
            type="text"
            id="subject"
            label="Subject"
            autoComplete="off"
            placeholder="Subject"
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
            isError={!!errors.subject}
            message={
              errors.subject && (
                <span>
                  <Info12Regular className="-mt-0.5" /> {errors.subject}
                </span>
              )
            }
            className="w-[1142px]"
          />
        </div>
        <div className="mb-5">
          <p className="text-body-sm font-semibold lg:mb-1">Body</p>
          <ReactQuill
            id="body"
            theme="snow"
            className="h-[300px] mb-[50px] rounded"
            value={body}
            onChange={setBody}
          />
          {errors.body && (
            <span className="text-red-500">
              <Info12Regular className="-mt-0.5" /> {errors.body}
            </span>
          )}
        </div>
        <div>
          <TextField
            type="text"
            id="background"
            label="Background"
            autoComplete="off"
            placeholder="Background Link"
            value={background}
            onChange={(event) => setBackground(event.target.value)}
            isError={!!errors.background}
            message={
              errors.background && (
                <span>
                  <Info12Regular className="-mt-0.5" /> {errors.background}
                </span>
              )
            }
            className="w-[1142px]"
          />
        </div>
        <label className="text-body-sm font-semibold">Kategori</label>
        <div>
          <select
            value={agama}
            name="Agama"
            onChange={(event) => setAgama(event.target.value)}
            className="border w-[30rem] h-[30px]"
          >
            <option value="0"></option>
            <option value="1">Kristen</option>
            <option value="2">Katolik</option>
            <option value="3">Islam</option>
            <option value="4">Hindu</option>
            <option value="5">Budha</option>
            <option value="6">Konghucu</option>
            <option value="7">All</option>
          </select>
          <p>
            {errors.agama && (
              <span className="text-error">
                <Info12Regular className="-mt-0.5" /> {errors.agama}
              </span>
            )}
          </p>
        </div>
        <div>
          <TextField
            type="color"
            id="color"
            label="Template Color"
            autoComplete="off"
            placeholder="Enter Template Color"
            value={color}
            onChange={(event) => setColor(event.target.value)}
            className="w-[11rem] h-[11rem]"
            isError={!!errors.color}
            message={
              errors.color && (
                <span>
                  <Info12Regular className="-mt-0.5" /> {errors.color}
                </span>
              )
            }
          />
        </div>
        <div>
          <FileInput
            type="file"
            id="attach"
            label="Attach"
            name="attach"
            onChange={(file) => setAttach(file)}
          />
        </div>
        <div className="flex justify-end">
          <Button className="mr-[10px]" size="md" id="add-alumni" onClick={handleAdd}>
            Save Event
          </Button>
          <Button size="md" id="preview-event" variant="lightgreen" onClick={handlePreview}>
            Preview Event
          </Button>
        </div>

        {previewData && (
          <div
          style={{
            backgroundImage: `url(${background})`,
            margin: 0,
            padding: 0,
            backgroundSize: "25%",
            borderRadius: 10
          }}
        >
          <section
            style={{
              backgroundColor: color,
              padding: 20,
              borderRadius: 10,
              margin: "20px auto",
              maxWidth: 600,
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)"
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src="https://raw.githubusercontent.com/Ivanpradipta/Gambar-skripsi/main/idulfitri.png"
                alt=""
                style={{ width: 100, height: "auto", display: "inline-block" }}
              />
            </div>
            <main style={{ marginTop: 8, color: "#666", lineHeight: "1.5" }}>
              <h2 style={{ color: "#333", fontWeight: "bold" }}>
                {title}
              </h2>
              <div dangerouslySetInnerHTML={{ __html: body }}></div>

              <p>
                This email is under development. If you have any suggestion, please
                contact ivan.pradipta@ti.ukdw.ac.id
              </p>
            </main>
          </section>
        </div>
        
        )}
      </form>
    </SecondaryContainer>
  );
}

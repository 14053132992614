import { Outlet } from "react-router-dom"
import AlumniSidebar from "./AlumniSidebar";
export default function AlumniLayout() {
    return(
        <>
            <AlumniSidebar>
                <Outlet />
            </AlumniSidebar>
        </>
          
    );
}
import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "../../Component/Button";
import TextField from "../../Component/TextField";
import MainContainer from "../../Component/MainContainer";
import ReactQuill from "react-quill";
import FileInput from "../../Component/FileInput";
import { AnimatePresence } from "framer-motion";
import Alert from "../../Component/Alert";
import { Info12Regular } from "@fluentui/react-icons";

const Formm = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [attach, setAttach] = useState(null);
  const [background, setBackground] = useState("");
  const [color, setColor] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [angkatan, setAngkatan] = useState("");
  const [angkatanDisabled, setAngkatanDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "",
  });

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          variant: "",
        });
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [alert.show]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setAngkatanDisabled(isChecked);

    if (!isChecked) {
      setEmail("");
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!isChecked) {
      if (!email) {
        newErrors.email = "Email tidak boleh kosong";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        newErrors.email =
          "Email harus mengandung karakter @ dan memiliki format yang benar";
      }
    }
    if (!subject) newErrors.subject = "Subject Tidak Boleh Kosong";
    if (!body) newErrors.body = "Body Tidak Boleh Kosong";
    if (isChecked) {
      if(!angkatan){
        newErrors.angkatan = "Tahun Angkatan Harus Diisi";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleonClick = async () => {
    if (!validate()) return;
    try {
      let apiUrl = "https://alumni-be.fti.ukdw.ac.id/api/send-email-alumni";

      if (isChecked) {
        apiUrl = "https://alumni-be.fti.ukdw.ac.id/api/send-emailjob";
      }

      const formData = new FormData();
      if (!isChecked) {
        formData.append("email", email);
      }
      formData.append("subject", subject);
      formData.append("body", body);
      formData.append("attach", attach);
      formData.append("background", background);
      formData.append("color", color);
      formData.append("isChecked", isChecked);
      formData.append("angkatan", angkatan);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(apiUrl, formData, config);
      console.log(response.data);

      setEmail("");
      setSubject("");
      setBody("");
      setAttach("");
      setAngkatan("");
      setBackground("")
      setColor("")
      setAlert({
        show: true,
        message: "Email Berhasil Dikirim",
        variant: "primary",
      });
    } catch (error) {
      console.error("Error while sending email:", error);
      setAlert({
        show: true,
        message: error.message || 'Email Gagal Dikirim',
        variant: 'error',
      });
    }
  };

  return (
    <MainContainer>
      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6`}
              variant={alert.variant}
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      <div className="flex">
        <div className="relative">
          <TextField
            type="email"
            id="email"
            label="Email"
            autoComplete="off"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            isError={!!errors.email}
            message={
              errors.email && (
                <span>
                  <Info12Regular className="" /> {errors.email}
                </span>
              )
            }
            className="w-[81.1rem] pr-8"
            disabled={isChecked}
          />
          <div className="absolute top-1/2 right-2 transform -translate-y-1/2 mt-[15px]">
            <label>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className=""
              />
            </label>
          </div>
        </div>
      </div>
      <div>
        <TextField
          type="text"
          id="subject"
          label="Subject"
          autoComplete="off"
          placeholder="Subject"
          value={subject}
          onChange={(event) => setSubject(event.target.value)}
          isError={!!errors.subject}
          message={
            errors.subject && (
              <span>
                <Info12Regular className="" /> {errors.subject}
              </span>
            )
          }
          className="w-[1142px]"
        />
      </div>
      <div className="mb-5">
        <p className="text-body-sm font-semibold lg:mb-1">Body</p>
        <ReactQuill
          id="body"
          theme="snow"
          className="h-[200px] mb-[50px]"
          value={body}
          onChange={setBody}
        />
        <p>
          {errors.body && (
            <span className="text-error">
              <Info12Regular className="-mt-0.5" /> {errors.body}
            </span>
          )}
        </p>
      </div>

      <div>
        <FileInput
          type="file"
          id="attach"
          label="Attach"
          name="attach"
          onChange={(file) => setAttach(file)}
        />
      </div>
      <TextField
        id="background"
        label="Background Image"
        autoComplete="off"
        placeholder="Enter Background Link"
        value={background}
        onChange={(event) => setBackground(event.target.value)}
        className="w-[1142px]"
        type="text"
      />
      <TextField
        type="color"
        id="color"
        label="Background Color"
        autoComplete="off"
        placeholder="Enter Background Link"
        value={color}
        onChange={(event) => setColor(event.target.value)}
        className="w-[11rem] h-[11rem]"
      />

      <div>
        <label className="text-body-sm font-semibold">Angkatan</label>
        <select
          id="angkatan"
          label="Angkatan"
          autoComplete="off"
          value={angkatan}
          onChange={(event) => setAngkatan(event.target.value)}
          className="w-full h-[40px] rounded-[4px] border mb-[10px]"
          disabled={angkatanDisabled}
        >
          <option value=""></option>
          <option value="2017">2017</option>
          <option value="2018">2018</option>
          <option value="2019">2019</option>
          <option value="2020">2020</option>
          <option value="2021">2021</option>
          <option value="all">All</option>
        </select>
      </div>
      <p>
        {errors.angkatan && (
          <span className="text-error">
            <Info12Regular className="-mt-0.5" /> {errors.angkatan}
          </span>
        )}
      </p>

      <div>
        <Button type="submit" onClick={handleonClick}>
          Simpan
        </Button>
      </div>
    </MainContainer>
  );
};

export default Formm;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SecondaryContainer from "../../../Component/SecondaryContainer";
import TextField from "../../../Component/TextField";
import Button from "../../../Component/Button";
import ReactQuill from "react-quill";
import FileInput from "../../../Component/FileInput";
import { ConfirmModal } from "../../../Component/Modal"; 
import Alert from "../../../Component/Alert";
import { AnimatePresence } from "framer-motion";
import { Info12Regular } from "@fluentui/react-icons";

export default function CreateBerita() {
  const [gambarBerita, setGambarBerita] = useState(null);
  const [namaBerita, setNamaBerita] = useState("");
  const [tanggalBerita, setTanggalBerita] = useState("");
  const [beritaData, setBeritaData] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [errors, setErrors] = useState({});

  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "",
  });

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          variant: "",
        });
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [alert.show]);
  const navigate = useNavigate();

  const handleonClick = (event) => {
    event.preventDefault();
    if (!validate()) return;
    setShowConfirmModal(true);
  };

  const validate = () => {
    const newErrors = {};
    if (!gambarBerita) newErrors.gambarBerita = "Gambar tidak boleh kosong";
    if (!namaBerita) newErrors.namaBerita = "Nama Lowongan tidak boleh kosong";
    if (!tanggalBerita) newErrors.tanggalBerita = "Tanggal tidak boleh kosong";
    if (!beritaData) newErrors.beritaData = "Berita Lowongan tidak boleh kosong";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleConfirmSave = async () => {
    try {
      const formData = new FormData();
      formData.append("gambar", gambarBerita);
      formData.append("nama", namaBerita);
      formData.append("tanggal_upload", tanggalBerita);
      formData.append("berita", beritaData);

      const res = await axios.post(
        "https://alumni-be.fti.ukdw.ac.id/api/berita",
        formData
      );

      setAlert({
        show: true,
        message: "Lowongan Berhasil ditambah",
        variant: "primary",
      });
      setTimeout(() => {
        navigate("/admin/7");
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        show: true,
        message: error.response.data.message,
        variant: "error",
      });
    }
    setShowConfirmModal(false);
  };

  return (
    <SecondaryContainer
      backTo="/admin/7"
      title="Tambah Lowongan Pekerjaan"
      className={"pe-3"}
    >
      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6 mt-8`}
              variant={alert.variant}
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      <form onSubmit={handleonClick} className="px-8">
        <div>
          <FileInput
            type="file"
            id="gambar"
            label="Gambar"
            name="Gambar"
            onChange={(file) => setGambarBerita(file)}
            isError={!!errors.gambarBerita}
            message={
              errors.gambarBerita && (
                <span>
                  <Info12Regular className="-mt-0.5" /> {errors.gambarBerita}
                </span>
              )
            }
          />
        </div>

        <div>
          <TextField
            id="article-title"
            label="Nama Lowongan"
            autoComplete="off"
            placeholder="Tulis Nama Lowongan"
            value={namaBerita}
            onChange={(event) => setNamaBerita(event.target.value)}
            className="w-[1142px]"
            isError={!!errors.namaBerita}
            message={
              errors.namaBerita && (
                <span>
                  <Info12Regular className="-mt-0.5" /> {errors.namaBerita}
                </span>
              )
            }
          />
        </div>

        <div>
          <TextField
            type="date"
            id="article-title"
            label="Tanggal Upload"
            autoComplete="off"
            placeholder="Tulis Tanggal Acara"
            value={tanggalBerita}
            onChange={(event) => setTanggalBerita(event.target.value)}
            className="w-[1142px]"
            isError={!!errors.tanggalBerita}
            message={
              errors.tanggalBerita && (
                <span>
                  <Info12Regular className="-mt-0.5" /> {errors.tanggalBerita}
                </span>
              )
            }
          />
        </div>
        <div className="mb-5">
          <p className="text-body-sm font-semibold lg:mb-1">Berita</p>
          <ReactQuill
            id="berita"
            theme="snow"
            className="h-[200px] mb-[50px] rounded"
            value={beritaData}
            onChange={setBeritaData}
          />
           {errors.beritaData && (
            <span className="text-red-500">
              <Info12Regular className="-mt-0.5" />{" "}
              {errors.beritaData}
            </span>
          )}
        </div>
        <div>
          <Button type="submit">
            Simpan
          </Button>
        </div>
      </form>

      {/* Modal Konfirmasi */}
      {showConfirmModal &&(
        <ConfirmModal
          title={"Konfirmasi Simpan Data Lowongan"}
          text={"Apakah Anda yakin ingin menyimpan data Lowongan ini?"}
          cancelText={"Batal"}
          confirmText={"Simpan"}
          isOpen={showConfirmModal}
          onCancel={() => setShowConfirmModal(false)}
          onConfirm={handleConfirmSave}
        />
      )}
    </SecondaryContainer>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import SecondaryContainer from "../../../Component/SecondaryContainer";


const DetailBerita = () => {
  const [berita, setBerita] = useState({});
  const param = useParams();

  useEffect(() => {
    const fetchBeritaDetail = async () => {
      try {
        const response = await axios.get(`https://alumni-be.fti.ukdw.ac.id/api/berita/${param.id}`);
        console.log(response.data);
        setBerita(response.data);
    } catch (error) {
        console.log(error);
      }
    };
  
    fetchBeritaDetail();
  }, [param.id]);
  

  return (
    <SecondaryContainer
      backTo="/admin/7"
      title="Detail Berita"
      className={"pe-3"}
    >
    <div className="px-6">
      <h2>Detail Berita</h2>
      <img className="w-[300px] h-[300px]" src={berita.gambar} alt={`Foto ${berita.nama}`} />
      <p>Nama: {berita.nama}</p>
      <p>Tanggal Lahir: {berita.tanggal_upload}</p>
      <p dangerouslySetInnerHTML={{ __html: "berita:" + berita.berita }}></p>
    </div>
    </SecondaryContainer>
  );
};

export default DetailBerita;

import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
// import {jwtDecode} from "jwt-decode";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = Cookies.get("token");
  const role = Cookies.get("role");

  const isValidToken = () => {
    if (token && role =='admin') {
      try {
        return true;
      } catch (error) {
        Cookies.remove("token");
        Cookies.remove("role")
        localStorage.removeItem("user");
        return false;
      }
    }
    return false;
  };

  return isValidToken() ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;

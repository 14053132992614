import Logo from "../assets/logoo.png";
import { Link } from "react-router-dom";
import Button from "./Button";

export default function LoginNavbar() {
  return (
    <div className="z-20">
      <nav className="sticky top-0 flex items-center justify-between px-12 py-[18px] bg-white z-20">
        <Link to={"/"}>
          <div className="flex items-center gap-2" id="logo-button">
            <img src={Logo} className="w-[100px]" />
          </div>
        </Link>
      </nav>
    </div>
  );
}

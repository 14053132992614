import { Link } from "react-router-dom";
import robot from "../assets/robot.png";

export default function Navbar() {
  return (
    <>
      <nav
        className="relative flex w-full flex-wrap items-center justify-between bg-[#343A40] py-2 shadow-dark-mild dark:bg-neutral-700 lg:py-4"
        data-twe-navbar-ref=""
      >
        <div className="flex w-full flex-wrap items-center justify-between px-3">
          <div>
            <a className="text-white mx-2 my-1 flex items-center lg:mb-0 lg:mt-0" href="#">
              <img
                className="h-[50px]"
                src={robot}
                alt="TE Logo"
                loading="lazy"
              />
              Alumni Relation
            </a>
          </div>
          <button
            className="block border-0 bg-transparent px-2 text-black/50 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
            type="button"
            data-twe-collapse-init=""
            data-twe-target="#navbarSupportedContent4"
            aria-controls="navbarSupportedContent4"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="[&>svg]:w-7 [&>svg]:stroke-black/50 dark:[&>svg]:stroke-neutral-200">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </button>
          <div
            className="!visible mt-2 hidden flex-grow basis-[100%] items-center lg:mt-0 lg:!flex lg:basis-auto"
            id="navbarSupportedContent4"
            data-twe-collapse-item=""
          >
            <ul
              className="list-style-none me-auto flex flex-col ps-0 lg:mt-1 lg:flex-row"
              data-twe-navbar-nav-ref=""
            >

            </ul>
            <div className="flex items-center">
              <Link to={`/login2`}>
                <button
                  type="button"
                  data-twe-ripple-init=""
                  data-twe-ripple-color="light"
                  className="me-3 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                >
                  Admin
                </button>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

import React, { useState, useEffect } from "react";
import SecondaryContainer from "../../Component/SecondaryContainer";
import Button from "../../Component/Button";
import axios from "axios";
import TextField from "../../Component/TextField";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import FileInput from "../../Component/FileInput";
import { AnimatePresence } from "framer-motion";
import Alert from "../../Component/Alert";

export default function UpdateEvent() {
  const [title, setTitle] = useState("");
  const [startEvent, setStartEvent] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [background, setBackground] = useState("");
  const [agama, setAgama] = useState("");
  const [color, setColor] = useState("");
  const [attach, setAttach] = useState(null);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://alumni-be.fti.ukdw.ac.id/api/events/${id}`);
        const event = response.data;
        setTitle(event.title || "");
        setStartEvent(event.start || "");
        setSubject(event.subject || "");
        setBody(event.body || "");
        setBackground(event.background || "");
        setAgama(event.agama || "");
        setColor(event.color || "");
        setAttach(event.attach || "");
      } catch (error) {
        console.error("Kesalahan saat mengambil data:", error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          variant: "",
        });
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [alert.show]);

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      const updateFormData = new FormData();

      if (title) updateFormData.append("title", title);
      if (startEvent) updateFormData.append("start", startEvent);
      if (subject) updateFormData.append("subject", subject);
      if (body) updateFormData.append("body", body);
      if (background) updateFormData.append("background", background);
      if (agama) updateFormData.append("agama", agama);
      if (color) updateFormData.append("color", color);
      if (attach) updateFormData.append("attach", attach);

      await axios.post(`https://alumni-be.fti.ukdw.ac.id/api/events/${id}`, updateFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setAlert({
        show: true,
        message: "Event berhasil diperbarui",
        variant: "primary",
      });
      setTimeout(() => {
        navigate("/admin/kalender");
      }, 3000);
    } catch (error) {
      console.error("Kesalahan saat memperbarui event:", error);
      setAlert({
        show: true,
        message: "Event gagal diperbarui",
        variant: "error",
      });
    }
  };

  return (
    <SecondaryContainer title="Edit Event" className={"pe-3"} backTo="/admin/kalender">
      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6`}
              variant={alert.variant}
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      <form>
        <div className="">
          <TextField
            type="text"
            id="eventName"
            label="Event Name"
            autoComplete="off"
            placeholder="Event Name"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            className="w-[1142px]"
          />
        </div>

        <div className="">
          <TextField
            type="date"
            id="eventDate"
            label="Event Date"
            autoComplete="off"
            placeholder=""
            value={startEvent}
            onChange={(event) => setStartEvent(event.target.value)}
            className="w-[1142px]"
          />
        </div>
        <div>
          <TextField
            type="text"
            id="subject"
            label="Subject"
            autoComplete="off"
            placeholder="Subject"
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
            className="w-[1142px]"
          />
        </div>
        <div className="mb-5">
          <p className="text-body-sm font-semibold lg:mb-1">Body</p>
          <ReactQuill
            id="body"
            theme="snow"
            className="h-[300px] mb-[50px] rounded"
            value={body}
            onChange={setBody}
          />
        </div>
        <div>
          <TextField
            type="text"
            id="background"
            label="Background Image"
            autoComplete="off"
            placeholder="Link Latar Belakang"
            value={background}
            onChange={(event) => setBackground(event.target.value)}
            className="w-[1142px]"
          />
        </div>
        <label className="text-body-sm font-semibold">Category</label>
        <div>
          <select
            value={agama}
            name="Agama"
            onChange={(event) => setAgama(event.target.value)}
            className="border w-[30rem] h-[30px]"
          >
            <option value=""></option>
            <option value="1">Kristen</option>
            <option value="2">Katolik</option>
            <option value="3">Islam</option>
            <option value="4">Hindu</option>
            <option value="5">Budha</option>
            <option value="6">Konghucu</option>
            <option value="7">Semua</option>
          </select>
        </div>
        <div>
          <TextField
            type="color"
            id="color"
            label="Template Color"
            autoComplete="off"
            value={color}
            onChange={(event) => setColor(event.target.value)}
            className="w-[11rem] h-[11rem]"
          />
        </div>
        <div>
          <FileInput
            type="file"
            id="attach"
            label="Attach"
            name="attach"
            value={attach}
            onChange={(file) => setAttach(file)}
          />
        </div>
        <div className="flex justify-end">
          <Button size="md" id="update-event" onClick={handleUpdate}>
            Perbarui Event
          </Button>
        </div>
      </form>
    </SecondaryContainer>
  );
}

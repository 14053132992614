import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { AnimatePresence } from "framer-motion";
import { Eye24Regular, EyeOff24Regular } from "@fluentui/react-icons";
import Alert from "../../Component/Alert";
import logo from "../../assets/logo_login.png";
import { useEffect } from "react";
import TextField from "../../Component/TextField";
import Button from "../../Component/Button";
import LoginNavbar from "../../Component/LoginNavbar";

function LoginPage2() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [token, setToken] = useState("");
  const [role, setRole] = useState("");
  const [alert, setAlert] = useState({
    show: false,
    message: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
        });
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [alert.show]);

  const handleLogin = async (event) => {
    event.preventDefault();

    if (email === "" || password === "") {
      setAlert({
        show: true,
        message: "Field Tidak Boleh Kosong",
      });
      return;
    }
    
    
    setLoading(true);
    setIsError(false);

    try {
      const response = await axios.post("https://alumni-be.fti.ukdw.ac.id/api/login", {
        email,
        password,
      });

      if (!response.data || !response.data.token) {
        throw new Error("Unexpected response format");
      }

      const token = response.data.token;
      const role = response.data.role;
      Cookies.set("token", token);
      Cookies.set("role", role);
      navigate("/admin/kalender");
    } catch (error) {
      setAlert({
        show: true,
        message: "Username atau password salah",
      });
      return;
    }

    setLoading(false);
  };
  const showEye = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <LoginNavbar />

      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6 mt-8`}
              variant="error"
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      <div
        className="h-[calc(100vh-152px)] py-8 flex flex-col items-center justify-center w-full"
        style={{
          backgroundImage: `url(${logo})`,
          backgroundSize: "1000px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div
          className="absolute inset-0 bg-[rgba(255,255,255,0.5)] backdrop-blur-md z-10"
          style={{ pointerEvents: "none" }}
        ></div>
        <div className="z-20">
          <form onSubmit={handleLogin}>
            <div className="bg-white rounded-md p-7">
              <div className="space-y-4">
                <h1 className="text-center text-h-4 font-bold text-[#10B981] flex justify-center items-center">
                  <img src={logo} alt="Logo" className="mr-2 w-[100px]" />
                </h1>
                <div>
                  <TextField
                    id="email-input"
                    label="Email"
                    className={`px-4 py-2 outline-none rounded-md w-full border ${
                      alert.show ? "border-red-500" : "border-neutral-30"
                    } `}
                    type="text"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="relative mb-4 flex w-full flex-wrap items-stretch">
                  <label
                    htmlFor="password"
                    className="block mb-1 text-body-sm font-semibold"
                  >
                    Kata Sandi
                  </label>
                  <input
                    id="password-input"
                    type={showPassword ? "text" : "password"}
                    className={`px-4 py-2 outline-none rounded-md w-full border ${
                      alert.show ? "border-red-500" : "border-neutral-30"
                    }`}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <button
                    id="eye-button"
                    className="absolute right-3 top-[3rem] transform -translate-y-1/2"
                    type="button"
                    onClick={showEye}
                  >
                    {showPassword ? <Eye24Regular /> : <EyeOff24Regular />}
                  </button>
                </div>
              </div>
              <Button
                id="submit-button"
                variant={"blue"}
                className={"rounded-full mt-10 w-full"}
                type="submit"
              >
                Masuk
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default LoginPage2;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Table from "../../../Component/Table";
import {
  Add20Regular,
  Edit20Regular,
  Eye20Regular,
  Delete20Regular,
  Dismiss20Filled,
} from "@fluentui/react-icons";
import Button from "../../../Component/Button";
import TextField from "../../../Component/TextField";
import PaginationButton from "../../../Component/PaginationButton";
import MainContainer from "../../../Component/MainContainer";
import { ConfirmModal } from "../../../Component/Modal";
import { motion, AnimatePresence } from "framer-motion";
import Alert from "../../../Component/Alert";

const renderAvatarPlaceHolder = (nama) => {
  return (
    <div className="avatar placeholder">
      <div className="bg-primary  rounded-full w-[105px] h-[101px] mb-[10px]">
        <p className="text-h-3 text-white">{nama.charAt(0).toUpperCase()}</p>
      </div>
    </div>
  );
};

const ITEMS_PER_PAGE = 20;

export default function ManajemenDB() {
  const [alumniData, setAlumniData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDelete, setShowDelete] = useState({ valid: false, id: null });
  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
  });

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
        });
      }, 2500);
    }
    fetchData();
    return () => clearTimeout(timer);
  }, [alert.show]);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://alumni-be.fti.ukdw.ac.id/api/alumnus");

      setAlumniData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (id_mahasiswa) => {
    try {
      const resp = await axios.delete(
        `https://alumni-be.fti.ukdw.ac.id/api/alumnus/${id_mahasiswa}`
      );
      setAlert({
        show: true,
        message: "Alumni Berhasil Dihapus",
        variant: "error",
      });
      fetchData();
      console.log(resp);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
    setShowDelete({ valid: false, id: null });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const totalPages = Math.ceil(
    Math.max(alumniData?.length || 0, 0) / ITEMS_PER_PAGE
  );
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const filteredAlumniData = Array.isArray(alumniData)
    ? alumniData.filter(
        (alumnus) =>
          alumnus.nama.toLowerCase().includes(searchTerm.toLowerCase()) ||
          alumnus.tanggal_lahir.includes(searchTerm.toString())||
          alumnus.nim.includes(searchTerm.toString())
      )
    : [];
  const visibleAlumniData = filteredAlumniData.slice(startIndex, endIndex);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const openModal = (data) => {
    setSelectedData(data);
    setShowModal(true);
  };

  const agamaMap = {
    1: 'Kristen',
    2: 'Katolik',
    3: 'Islam',
    4: 'Hindu',
    5: 'Budha',
    6: 'Konghucu',
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  return (
    <MainContainer>
      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6 mt-8`}
              variant="error"
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      {showDelete.valid ? (
        <ConfirmModal
          title={"Konfirmasi Hapus Data Alumni"}
          text={"Yakin ingin menghapus data alumni ini?"}
          cancelText={"Tidak"}
          confirmText={"Ya"}
          icon={"delete"}
          isOpen={showDelete ? true : false}
          onCancel={() => {
            setShowDelete({ valid: false, id: null });
          }}
          onConfirm={() => {
            handleDelete(showDelete.id);
          }}
        />
      ) : null}
      <div className="grid grid-cols-2 w-full mt-5">
        <div className="relative flex w-3/4 items-stretch">
          <TextField
            id="search-alumni"
            autoComplete="off"
            variant="search"
            placeholder="Ketik nama Alumni"
            type="search"
            className={"pe-2"}
            onChange={handleSearchChange}
          ></TextField>
        </div>
        <Link to="/admin/alumni/create" className="justify-self-end text-black">
          <Button size="md" id="add-alumni" className="text-black">
            <Add20Regular className="mr-2" />
            <span className="text-black">Tambah</span>
          </Button>
        </Link>
      </div>

      <Table
        headers={[
          "Foto",
          "Nama",
          "NIM",
          "Tanggal Lahir",
          "Lulus",
          "Email",
          "Agama",
          "Aksi",

        ]}
        className={
          "overflow-y-scroll mt-7 w-full overflow-x-hidden text-[#030712]"
        }
      >
        {visibleAlumniData.map((alumnus) => {
          return (
            <tr key={alumnus.id_mahasiswa} className="text-center">
              <td className="w-[80px] h-[80px] relative p-3">
                {alumnus.foto ? (
                  <img
                    src={alumnus.foto}
                    className="mx-auto"
                    alt={`Foto ${alumnus.nama}`}
                  />
                ) : (
                  <div className="w-[80px] h-[80px] bg-gray-200  flex items-center justify-center text-gray-500 text-xl">
                    {alumnus.nama.charAt(0)}
                  </div>
                )}
              </td>
              <td className="pb-[5px]">{alumnus.nama}</td>
              <td className="pb-[5px]">{alumnus.nim}</td>
              <td className="pb-[5px]">{formatDate(alumnus.tanggal_lahir)}</td>
              <td className="pb-[5px]">{formatDate(alumnus.lulus)}</td>
              <td className="pb-[5px]">{alumnus.email}</td>
              <td className="pb-[5px]">{agamaMap[alumnus.agama]}</td>
              {/* <td className="pb-[5px]">{alumnus.linkedin}</td> */}
              <td className="pb-[5px]">
                <div className="flex gap-3 justify-center">
                  <Eye20Regular
                    className="cursor-pointer hover:text-info"
                    onClick={() => openModal(alumnus)}
                  />
                  <Delete20Regular
                    className="cursor-pointer hover:text-info"
                    onClick={() =>
                      setShowDelete({ valid: true, id: alumnus.id_mahasiswa })
                    }
                  />
                  <Link to={`/admin/alumni/${alumnus.nim}`}>
                    <Edit20Regular className="cursor-pointer hover:text-info" />
                  </Link>
                </div>
              </td>
            </tr>
          );
        })}
      </Table>

      {/* modal */}
      {selectedData && showModal && (
        <>
          <div
            className="fixed inset-0 z-40 bg-black opacity-50"
            onClick={() => setShowModal(false)}
          ></div>
          <motion.div className="fixed inset-0 flex items-center justify-center z-50">
            <motion.div
              className="border bg-neutral-10 w-auto rounded-[10px] p-[32px] shadow-elevation-2 border-1 top-1/2 left-1/2"
              initial={{ opacity: 0, y: +70 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className="flex justify-between mb-[10px]">
                <p className="font-bold">Data Diri Alumni</p>
                <Dismiss20Filled
                  onClick={() => setShowModal(false)}
                  className="cursor-pointer hover:text-info"
                  id="close-modal"
                />
              </div>
              <div className="flex">
                <div className="">
                  <div className="flex flex-col items-center">
                    {selectedData.foto ? (
                      <div className="w-[105px] h-[101px] rounded-full mb-[10px]">
                        <img
                          src={selectedData.foto}
                          alt=""
                          className="rounded-full object-cover w-full h-full"
                        />
                      </div>
                    ) : (
                      renderAvatarPlaceHolder(selectedData.nama)
                    )}
                    <div className="text-center">
                      <p>{selectedData.nama}</p>
                      <p>{selectedData.email}</p>
                    </div>
                  </div>
                </div>
                <div className="ml-[32px]">
                  <label className="font-bold">NIM</label>
                  <p>{selectedData.nim}</p>
                  <label className="font-bold">Tanggal Lulus</label>
                  <p>
                    {new Date(selectedData.lulus).toLocaleDateString("id-ID")}
                  </p>
                  <label className="font-bold">Tanggal Lahir</label>
                  <p>
                    {new Date(selectedData.tanggal_lahir).toLocaleDateString(
                      "id-ID"
                    )}
                  </p>
                  <label className="font-bold">Tahun Lulus</label>
                  <p>
                    <p>{selectedData.tahun_angkatan}</p>
                  </p>
                </div>
                <div className="ml-[32px]">
                  <label className="font-bold">Linkedin</label>
                  <p>{selectedData.linkedin}</p>
                  <label className="font-bold">Agama</label>
                  <p>
                    <p>{selectedData.agama}</p>
                  </p>
                  <label className="font-bold">Gender</label>
                  <p>
                    <p>{selectedData.jenis_kelamin}</p>
                  </p>
                </div>
                <div className="ml-[32px]">
                  <label className="font-bold">Linkedin</label>
                  <p>{selectedData.nama_perusahaan}</p>
                  <label className="font-bold">Agama</label>
                  <p>
                    <p>{selectedData.agama}</p>
                  </p>
                  <label className="font-bold">Gender</label>
                  <p>
                    <p>{selectedData.jenis_kelamin}</p>
                  </p>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </>
      )}

      <div className="flex w-full justify-center mt-5">
        <PaginationButton
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages}
        />
      </div>
    </MainContainer>
  );
}

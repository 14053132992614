import { createBrowserRouter } from "react-router-dom";
import ManajemenDB from "./Pages/DB/Alumni/ManajemenDB";
import AdminLayout from "./Component/Layout";
import CreateDB from "./Pages/DB/Alumni/CreateDB";
import Calendarr from "./Pages/Event/Calendar";
import DetailDB from "./Pages/DB/Alumni/DetailDB";
import Form from "./Pages/Email/Form";
import EditDB from "./Pages/DB/Alumni/EditDB";
import AllEvents from "./Pages/Event/AllEvent";
import LoginPage from "./Pages/Login/Login";
import ProtectedRoute from "./Routes/ProtectedRoute";
import PrivateRoute from "./Routes/PrivateRoute";
import AlumniRoute from "./Routes/AlumniRoute";
import Message from "./Pages/Email/Message";
import LandingPage from "./Pages/Landing Page/LandingPage";
import ManajemenBerita from "./Pages/DB/Berita/ManajemenBerita";
import CreateBerita from "./Pages/DB/Berita/CreateBerita";
import EditBerita from "./Pages/DB/Berita/EditBerita";
import DetailBerita from "./Pages/DB/Berita/DetailBerita";
import EditAlumni from "./Pages/DB/Alumni/EditAlumni";
import ResetPassword from "./Pages/Landing Page/Reset";
import LoginPage2 from "./Pages/Login/Login2";
import AlumniLayout from "./Component/Alumni/AlumniLayout";
import CreateEvent from "./Pages/Event/CreateEvent";
import UpdateEvent from "./Pages/Event/UpdateEvent";


const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        path: "/",
        element: <AlumniRoute component={LandingPage}/>,
          // element: <LandingPage/>,
      },
      {
        path: "/login",
        element: <LoginPage/>,
          // element: <LandingPage/>,
      },
      {
        path: "/login2",
        element: <ProtectedRoute component={LoginPage2} />,
      },
    ],
  },
  {
    path: "/alumni",
    element:<AlumniLayout/>,
    children: [
    {
      path: "/alumni/:nim",
      element:<EditAlumni/>,
    },
    {
      path: "/alumni/reset",
      element:<ResetPassword/>,
    },]
  },
  {
    path: "/admin",
    // element:<AdminLayout/> ,
    element:<PrivateRoute component={AdminLayout} /> ,
    children: [
      {
        path: "/admin/alumni",
        element: <ManajemenDB/>,
      },
      {
        path: "/admin/alumni/create",
        element: <CreateDB />,
      },
      {
        path: "/admin/:nim",
        element: <DetailDB />
      },
      {
        path: "/admin/alumni/:nim",
        element: <EditDB />
      },
      {
        path: "/admin/3",
        element: <Message />,
      },
      {
        path: "/admin/kalender",
        element: <Calendarr />
      },
      
      {
        path: "/admin/6",
        element: <Form />
      },
      {
        path: "/admin/7",
        element: <ManajemenBerita />
      },
      
      {
        path: "/admin/9",
        element: <CreateBerita />
      },
      {
        path: "/admin/10/:id",
        element: <EditBerita />
      },
      {
        path: "/admin/11/:id",
        element: <DetailBerita />
      },
      {
        path: "/admin/kalender/events",
        element: <AllEvents />
      },
      {
        path: "/admin/kalender/1",
        element: <CreateEvent />
      },
      {
        path: "/admin/15/:id",
        element: <UpdateEvent />
      },
    ]
  }
]);

export default router;

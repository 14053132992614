import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SecondaryContainer from "../../../Component/SecondaryContainer";
import TextField from "../../../Component/TextField";
import Button from "../../../Component/Button";
import FileInput from "../../../Component/FileInput";
import Alert from "../../../Component/Alert";
import { AnimatePresence } from "framer-motion";
import { Info12Regular } from "@fluentui/react-icons";


export default function CreateDB() {
  const [namaAlumni, setNamaAlumni] = useState("");
  const [nim, setNim] = useState("");
  const [emailAlumi, setEmailAlumni] = useState("");
  const [lahirAlumni, setLahirAlumni] = useState("");
  const [lulusAlumni, setLulusAlumni] = useState("");
  const [fotoAlumni, setFotoAlumni] = useState(null);
  const [agamaAlumni, setAgamaAlumni] = useState("");
  const [linkedAlumni, setLinkedAlumni] = useState("");
  const [angkatan, setAngkatan] = useState("");
  const [gender, setGender] = useState("");
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "",
  });

  useEffect(() => {
    let timer;
    if (alert.show) {
      timer = setTimeout(() => {
        setAlert({
          show: false,
          message: "",
          variant: "",
        });
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [alert.show]);

  const navigate = useNavigate();
  const validate = () => {
    const newErrors = {};
    if (!namaAlumni) newErrors.namaAlumni = "Nama Alumni tidak boleh kosong";
    if (!nim) newErrors.nim = "NIM tidak boleh kosong";
    if (!emailAlumi) newErrors.emailAlumi = "Email tidak boleh kosong";
    if (!lahirAlumni) newErrors.lahirAlumni = "Tanggal Lahir tidak boleh kosong";
    if (!lulusAlumni) newErrors.lulusAlumni = "Tahun Lulus tidak boleh kosong";
    if (!agamaAlumni) newErrors.agamaAlumni = "Kategori tidak boleh kosong";
    if (!angkatan) newErrors.angkatan = "Tahun Angkatan tidak boleh kosong";
    if (!gender) newErrors.gender = "Gender tidak boleh kosong";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleOnClick = async (event) => {
    event.preventDefault();
    if (!validate()) return;


    try {
      const formData = new FormData();
      formData.append("nama", namaAlumni);
      formData.append("nim", nim);
      formData.append("email", emailAlumi);
      formData.append("tanggal_lahir", lahirAlumni);
      formData.append("tahun_angkatan", angkatan);
      formData.append("jenis_kelamin", gender);
      formData.append("tanggal_yudisium", lulusAlumni);
      if (fotoAlumni) {
        formData.append("foto", fotoAlumni);
      }
      formData.append("agama", agamaAlumni);
      if (linkedAlumni) {
        formData.append("linkedin", linkedAlumni);
      }

      const alumniResponse = await axios.post(
        "https://alumni-be.fti.ukdw.ac.id/api/store-alumnus",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(alumniResponse.data);
      setAlert({
        show: true,
        message: "Data Berhasil ditambah",
        variant: "primary",
      });
      setTimeout(() => {
        navigate("/admin/alumni");
      }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({
        show: true,
        message: "Data gagal ditambah",
        variant: "error",
      });
    }
  };

  return (
    <SecondaryContainer
      backTo="/admin/alumni"
      title="Tambah Data Alumni"
      className={"pe-3"}
    >
      <div className="flex w-full justify-center">
        <AnimatePresence>
          {alert.show && (
            <Alert
              className={`fixed w-4/6 mt-8`}
              variant={alert.variant}
              message={alert.message}
            />
          )}
        </AnimatePresence>
      </div>
      <form onSubmit={handleOnClick} className="px-8">
        <div>
          <FileInput
            type="file"
            id="foto"
            label="Foto"
            name="foto"
            onChange={(file) => setFotoAlumni(file)}
          />
        </div>

        <div>
          <TextField
            id="article-title"
            label="Nama Alumni"
            autoComplete="off"
            placeholder="Tulis Nama Alumni"
            value={namaAlumni}
            onChange={(event) => setNamaAlumni(event.target.value)}
            isError={!!errors.namaAlumni}
            message={
              errors.namaAlumni && (
                <span>
                  <Info12Regular className="-mt-0.5" /> {errors.namaAlumni}
                </span>
              )
            }
            className="w-[1142px]"
          />
        </div>
        <div>
          <TextField
            id="article-title"
            label="Email Alumni"
            autoComplete="off"
            placeholder="Tulis Email Alumni"
            value={emailAlumi}
            onChange={(event) => setEmailAlumni(event.target.value)}
            isError={!!errors.emailAlumi}
            message={
              errors.emailAlumi && (
                <span>
                  <Info12Regular className="-mt-0.5" /> {errors.emailAlumi}
                </span>
              )
            }
            className="w-[1142px]"
          ></TextField>
        </div>

        <div>
          <TextField
            id="article-title"
            label="NIM"
            autoComplete="off"
            placeholder="Tulis NIM Alumni"
            value={nim}
            onChange={(event) => setNim(event.target.value)}
            isError={!!errors.nim}
            message={
              errors.nim && (
                <span>
                  <Info12Regular className="-mt-0.5" /> {errors.nim}
                </span>
              )
            }
            className="w-[1142px]"
          ></TextField>
        </div>

        <div>
          <TextField
            type="date"
            id="article-title"
            label="Tanggal Lahir"
            autoComplete="off"
            placeholder="Tulis Tanggal Lahir Alumni"
            value={lahirAlumni}
            onChange={(event) => setLahirAlumni(event.target.value)}
            isError={!!errors.lahirAlumni}
            message={
              errors.lahirAlumni && (
                <span>
                  <Info12Regular className="-mt-0.5" /> {errors.lahirAlumni}
                </span>
              )
            }
            className="w-[1142px]"
          ></TextField>
        </div>
        <div>
          <TextField
            type="date"
            id="article-title"
            label="Tanggal Yudisium"
            autoComplete="off"
            placeholder="Tulis Tanggal Yudisium Alumni"
            value={lulusAlumni}
            onChange={(event) => setLulusAlumni(event.target.value)}
            isError={!!errors.lulusAlumni}
            message={
              errors.lulusAlumni && (
                <span>
                  <Info12Regular className="-mt-0.5" /> {errors.lulusAlumni}
                </span>
              )
            }
            className="w-[1142px]"
          ></TextField>
        </div>

        <div>
          <TextField
            type="text"
            id="article-title"
            label="Tahun Angkatan"
            autoComplete="off"
            placeholder="Tulis Tahun Angkatan Alumni"
            value={angkatan}
            onChange={(event) => setAngkatan(event.target.value)}
            isError={!!errors.angkatan}
            message={
              errors.angkatan && (
                <span>
                  <Info12Regular className="-mt-0.5" /> {errors.angkatan}
                </span>
              )
            }            className="w-[1142px]"
          ></TextField>
        </div>

        <div>
          <TextField
            id="article-title"
            label="Linkedin Alumni"
            autoComplete="off"
            placeholder="Tulis Linkedin Alumni"
            value={linkedAlumni}
            onChange={(event) => setLinkedAlumni(event.target.value)}
            className="w-[1142px]"
          ></TextField>
        </div>

        <label className="text-body-sm font-semibold">Gender</label>

        <div>
          <select
            value={gender}
            name="Agama"
            onChange={(event) => setGender(event.target.value)}
            className="border w-full h-[40px] rounded-[4px]"
          >
            <option value="0"></option>
            <option value="L">Laki - Laki</option>
            <option value="P">Perempuan</option>
          </select>
          <p>{
              errors.gender && (
                <span className="text-error">
                  <Info12Regular className="-mt-0.5" />{" "}
                  {errors.gender}
                </span>
              )
            }</p>
        </div>
        <label className="text-body-sm font-semibold">Agama</label>
        <div>
          <select
            value={agamaAlumni}
            name="Agama"
            onChange={(event) => setAgamaAlumni(event.target.value)}
            className="border w-full h-[40px] mb-[10px] rounded-[4px]"
          >
            <option value="0"></option>
            <option value="1">Kristen</option>
            <option value="2">Katolik</option>
            <option value="3">Islam</option>
            <option value="4">Hindu</option>
            <option value="5">Budha</option>
            <option value="6">Konghucu</option>
          </select>
        </div>
        <p>{
              errors.agamaAlumni && (
                <span className="text-error">
                  <Info12Regular className="-mt-0.5" />{" "}
                  {errors.agamaAlumni}
                </span>
              )
            }</p>

        <div>
          <Button type="submit" onClick={handleOnClick}>
            Simpan
          </Button>
        </div>
      </form>
    </SecondaryContainer>
  );
}

import { useState, useEffect } from "react";
import { SignOut24Regular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

export default function MainContainer({ children }) {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    setInterval(() => setDate(new Date()), 30000);
  }, []);

  const handleLogOut = async () => {
    try {
      const token = Cookies.get("token");
  
      if (!token) {
        navigate("/login");
        return;
      }
  
      const response = await axios.post("https://alumni-be.fti.ukdw.ac.id/api/logout", null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        Cookies.remove("token");
        localStorage.removeItem("user");
  
        navigate("/login2");
      } else {
        console.error("Failed to log out:", response.data.message);
      }
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };


  return (
    <div className="px-[55px] py-[54px] w-full">
      <div className="flex justify-between items-center">
        <p className="text-body-lg">
          <span className="pe-1.5 border-e border-black">
            {date.toLocaleDateString("id-ID", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </span>
          <span className="ps-1.5">
            {date.toLocaleTimeString("id-ID", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        </p>
        <div className="flex gap-5 p-2.5">
          <div className="flex items-center gap-2.5">
            <img
              src={""}
              className="w-9 rounded-lg"
              alt=""
            />
            <p className="font-semibold text-body-sm">Admin</p>
          </div>
          <button
            className="flex items-center gap-2"
            onClick={handleLogOut}
          >
            <SignOut24Regular className="hover:text-info transition-colors duration-150" />
          </button>
        </div>
      </div>
      {children}
    </div>
  );
}
